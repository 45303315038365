import { Injectable } from '@angular/core';
import { AlertButton, AlertController, AlertInput, ToastController } from '@ionic/angular';

@Injectable({
	providedIn: 'root',
})
export class AlertService {
	constructor(
		private alertController: AlertController,
		private toastController: ToastController,
	) {}

	async presentAlertOptions(header: string, message: string, inputs: AlertInput[], buttons: AlertButton[]) {
		console.log('inputs', inputs);
		const alert = await this.alertController.create({
			header,
			message,
			cssClass: 'alertAzul',
			buttons: [
				{
					text: 'Cancelar',
					role: 'cancel',
				},
				...buttons,
			],
			inputs,
			backdropDismiss: false,
		});
		console.log('alerting');
		
		await alert.present();
		
		console.log('alerting END');
	}

	async presentAlertAdvise(header: string, message: string, confirmText?: string, cb?: () => void) {
		const alert = await this.alertController.create({
			header,
			message,
			cssClass: 'alertAzul',
			buttons: [
				{
					text: confirmText ?? 'Aceptar',
					handler: cb,
				},
			],
			backdropDismiss: false,
		});

		await alert.present();
	}

	async presentAlertConfirm(
		header: string,
		message: string,
		confirmText: string,
		cancelText: string,
		confirmHandler: (value: any) => void,
		cancelHandler: (value: any) => void,
	) {
		const alert = await this.alertController.create({
			header,
			message,
			cssClass: 'alertAzul',
			buttons: [
				{
					text: cancelText,
					role: 'cancel',
					cssClass: 'secondary',
					handler: cancelHandler,
				},
				{
					text: confirmText,
					handler: confirmHandler,
				},
			],
			backdropDismiss: false,
		});

		await alert.present();
	}

	async toast(message: string, duration: number = 2000) {
		const toast = await this.toastController.create({
			message: message,
			duration: duration,
			position: 'bottom',
		});

		await toast.present();
	}
}
