import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { RoleTypes } from '@models/role-types.enum';
import { AuthService } from '@services/auth.service';
import { StorageService } from '@services/storage.service';

@Injectable({
	providedIn: 'root',
})
export class ApiService {
	static readonly BASE_URL: string = environment.API_BASE_URL;
	static readonly BASE_URL_BIOMETRIC: string = environment.API_BIOMETRIC_URL;
	readonly token = new BehaviorSubject<string>('');
	public headers: HttpHeaders;
	private baseUrlSpike = 'https://api.spikeapi.com/';
	private spikeAuth = '0304e2ea-f8df-435f-8e6c-7ec9245eed1f';

	constructor(
		protected http: HttpClient,
		private storageService: StorageService,
		private authService: AuthService,
	) {
		// Headers sin token de autenticación
		const headersWithoutToken = new HttpHeaders({
			'Content-Type': 'application/json',
			Accept: 'application/json',
		});
		this.headers = headersWithoutToken;

		// Actualizacion de headers con token de autenticación
		this.token.subscribe((token) => {
			if (token == '') {
				this.headers = headersWithoutToken;
				return;
			}
			// Actualiza el token de autenticación en el header de la petición HTTP
			this.headers = new HttpHeaders({
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: 'Bearer ' + token,
			});
		});

		// Actualizacion de token de autenticación mediante el servicio de almacenamiento
		let session = this.storageService.getSession();
		if (session) this.token.next(session.accessToken);
	}

	/**
	 * BASICS methods for API requests
	 */
	protected handleError(error: HttpErrorResponse) {
		if (error.error instanceof ErrorEvent) {
			// A client-side or network error occurred. Handle it accordingly.
			console.error('Ocurrió un error:', error.error.message);
		} else {
			// The backend returned an unsuccessful response code.
			// The response body may contain clues as to what went wrong,
			console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
		}
		// return an observable with a user-facing error message
		// return throwError(() => { return new Error('Algo malo sucedió; por favor inténtelo de nuevo más tarde.'); });
		return throwError(() => {
			return error;
		});
	}

	protected GET = (endpoint: string, params?: HttpParams): Observable<any> =>
		this.http
			.get(ApiService.BASE_URL + '/' + endpoint, { headers: this.headers, params: params })
			.pipe(catchError(this.handleError));
	protected POST = (endpoint: string, data: any = {}): Observable<any> =>
		this.http
			.post(ApiService.BASE_URL + '/' + endpoint, data, { headers: this.headers })
			.pipe(catchError(this.handleError));
	protected PUT = (endpoint: string, data: any = {}): Observable<any> =>
		this.http
			.put(ApiService.BASE_URL + '/' + endpoint, data, { headers: this.headers })
			.pipe(catchError(this.handleError));
	protected DELETE = (endpoint: string): Observable<any> =>
		this.http
			.delete(ApiService.BASE_URL + '/' + endpoint, { headers: this.headers })
			.pipe(catchError(this.handleError));
	protected PATCH = (endpoint: string, data: any = {}): Observable<any> =>
		this.http
			.patch(ApiService.BASE_URL + '/' + endpoint, data, { headers: this.headers })
			.pipe(catchError(this.handleError));

//BIOMETRICS
	protected POST_BIOMETRIC = (endpoint: string, data: any = {}): Observable<any> =>
		this.http
			.post(ApiService.BASE_URL_BIOMETRIC + '/' + endpoint, data, { headers: this.headers })
			.pipe(catchError(this.handleError));
	/**
	 * AUTH
	 */
	public signIn(email: string, password: string, code: string = null) {
		return this.POST('auth/login', { email, password, code }).pipe(
			tap((res) => {
				if (res.data.accessToken) {
					this.token.next(res.data.accessToken);
					this.authService.login(res.data);
				}
				return res;
			}),
		);
	}

	public signUp(
		name: string,
		last_name: string,
		fecha_nacimiento: string,
		country: string,
		state: string,
		email: string,
		phone: string,
		password: string,
		detail: any = {},
		role: RoleTypes = RoleTypes.PATIENT,
	) {
		return this.POST('auth/signup', {
			name,
			last_name,
			fecha_nacimiento,
			country,
			state,
			email,
			cel: phone,
			phone,
			password,
			detail,
			role,
		});
	}

	public signOut() {
		return this.POST('auth/logout').pipe(
			tap((res) => {
				this.token.next('');
				this.authService.logout();
				return res;
			}),
		);
	}


	public recoverPassword(email: string) {
		return this.POST('users/password-recovery', { email });
	}

	/**
	 * USER
	 */
	public getUser(userId: number | string) {
		return this.GET(`v2/users/${userId}`);
	}
	public getUserProfile(userId: number | string) {
		return this.GET('users/' + userId + '/profile');
	}
	public updateProfile(
		userId: number | string,
		user: {
			name: string;
			last_name: string;
			birthdate: string;
			age?: string;
			phone: string;
			cel: string;
			email?: string;
			country?: string;
			state?: string;
			zipcode?: string;
			emergency_contact?: string;
			emergency_relation?: string;
			emergency_phone?: string;
		},
	) {
		return this.PATCH('users/' + userId, user);
	}

	public updateProfilePicture(userId, image: string) {
		return this.POST('users/' + userId + '/photo', { photo: image });
	}

	public getChatToken() {
		return this.GET('users/chat-token');
	}

	public getVideoToken() {
		return this.GET('users/video-token');
	}

	public updateFCMToken(token: string) {
		return this.POST('users/fcm-token', { fcm_token: token });
	}

	public updateRoomStatus(roomId: string, userId: number) {
		return this.POST('twilio/room-status', { roomId, userId });
	}

	public notification(
		userId: number | string,
		data: { title: string; body: string; navigation_url: string; channel: string },
	) {
		return this.POST('users/' + userId + '/notification', data);
	}

	public assingTherapistChannel(patientId: number) {
		return this.POST(`users/${patientId}/assign-therapist-chanel`);
	}

	public assingSupportChannel(patientId: number){
		return this.POST( `users/${patientId}/assign-support-chanel`);
	}
	public checkSessionTime() {
		return this.GET('users/check-session-time');
	}
	public startVideoCall(data: { receiverId: number | string }) {
		return this.POST('users/call-to-user', data);
	}

	public endVideoCall(sid: string, remoteId: number) {
		return this.POST('users/end-call', { sid, id: remoteId });
	}

	/**
	 * USER - THERAPIST
	 */
	public therapistDetail(detailId: number | string) {
		return this.GET(`/therapist-detail/${detailId}`);
	}

	public getMyPatients() {
		return this.GET('v2/users/my-patients');
	}

	public getTodayPatients() {
		return this.GET('v2/users/today-patients');
	}

	public updateTherapistDetail(
		detailId,
		detailData: {
			professional_id: string;
			address: string;
			zipcode: number | string;
			supervising_years: number;
			therapy_years: number;
			bio: string;
			birthdate: string;
		},
	) {
		return this.PATCH(`therapist-detail/${detailId}`, detailData);
	}

	public assignTherapist(patientId: number, therapistId: number) {
		return this.POST(`users/${patientId}/therapist`, { therapistId });
	}
	/**
	 * USER - PATIENT
	 */

	public getTherapists(id?: number) {
		if (id) {
			return this.GET(`v2/users/therapists/${id}`);
		} else {
			return this.GET('v2/users/therapists');
		}
	}

	public getTherapist() {
		return this.GET('v2/users/my-therapist');
	}

	/**
	 * SCHEDULE
	 */
	public getMySchedules(user_id: number) {
		let params: HttpParams = new HttpParams()
			.set('join', 'therapists')
			.set('s', `{"therapists.id": {"$eq": ${user_id}}, "status": {"$in": [1, 3]}}`);

		return this.GET('schedules', params);
	}

	public createSchedule(data: any) {
		return this.POST('schedules', data);
	}

	public updateSchedule(schedule_id: number, data: any) {
		return this.PATCH('schedules/' + schedule_id, data);
	}

	public deleteSchedule(schedule_id: number) {
		return this.DELETE('schedules/' + schedule_id);
	}

	/**
	 * SPECIALTY
	 */
	public getSpecialty(id: string) {
		return this.GET(`specialty/${id}`);
	}

	public getSpecialties() {
		return this.GET('specialty');
	}

	public getSpecialtyImage(path: string) {
		return this.GET(`specialty/image/${path}`);
	}

	/**
	 * Content
	 */
	public getContentImage(path: string) {
		return this.GET(`content/image/${path}`);
	}

	public getSpecialtyContent(id: number) {
		return this.GET(`content/specialty/${id}`);
	}

	public getLastContents(userId: number) {
		return this.GET(`content-visit/${userId}/lastContents`);
	}

	public postContentVisit(data: any) {
		return this.POST(`content-visit/`, data);
	}

	public getCategories() {
		return this.GET('category');
	}

	public getSubcategories() {
		return this.GET('subcategory');
	}

	public getSubategoryContents(id: number, id_subcategory: number) {
		return this.GET(`content/${id}/category/${id_subcategory}/subcategory`);
	}

	public getCategory(id: number) {
		return this.GET(`category/${id}`);
	}

	public getLastContent() {
		return this.GET('category/last-content');
	}

	/**
	 * PLANES DE PAGO
	 */

	public getPackages() {
		return this.GET('package');
	}

	/**
	 * STRIPE METHODS
	 */
	public getCouponsCode(code: string) {
		return this.GET(`coupons/code/${code}`);
	}

	public createSubscriptionLight(data: any) {
		return this.POST('payments/create-subscription-light', data);
	}

	/**
	 * APPOINTMENTS
	 */

	public getUserAppointments(id: number) {
		return this.GET(`v2/users/${id}/appointments`);
	}

	public getPackagesAvailability(id: number) {
		return this.GET(`users/${id}/packages-availability`);
	}

	public getTherapisthAvailability(id: number) {
		return this.GET(`users/${id}/availabilty`);
	}

	public createAppointment(appointment: any) {
		return this.POST('appointments', appointment);
	}

	public cancelAppointment(id: number) {
		return this.POST(`appointments/${id}/cancel`, '');
	}

	/**
	 * Feeling
	 */

	public getFeeling() {
		return this.GET('feeling');
	}

	public getFeelingContents(id: number) {
		return this.GET(`feeling/${id}/contents`);
	}

	public feelingVisit(id: string, startDate?: any, endDate?: any){
		return this.POST(`feeling-visit/user/${id}`, {startDate, endDate});
	}

	/**
	 * Chat - Bot
	 */

	public supportChat(data: {
		type: 'text' | 'video' | 'audio' | 'contact' | 'document' | 'image' | 'location' | 'sticker';
		flag: 'start' | 'bot' | 'therapist';
		body: string;
	}) {
		return this.POST('users/support-chat', data);
	}

	/**
	 * Users
	 */
	public getUsers(){
		return this.GET('v2/users');
	}

	public sendVerificationEmail(email: string) {
		return this.POST(`auth/send-verification/${email}`);
	}

	/**
	 * Thumbnail images
	 */

	public uploadImage(id: number, photo: string){
		return this.POST(`v2/users/${id}/photo`, {photo});
	}

	/**
	 * Spike
	 */

	public setSpikeSid(id: number, sid: string){
		return this.POST(`users/${id}/spike`, {sid});
	}

	public spikeUser(id: string, startDate?: any, endDate?: any){
		return this.POST_BIOMETRIC(`spike/user/${id}`, {startDate, endDate})
	}

	getUserData(userId: string): Observable<any> {
		const url = `${this.baseUrlSpike}users/${userId}`;
		const headers = new HttpHeaders().set('x-spike-auth', this.spikeAuth);
		return this.http.get(url, { headers });
	}

	deleteUserIntegration(userId: string, integrationsToRemove: string[]): Observable<any> {
		const url = `${this.baseUrlSpike}users/${userId}/remove-integrations`;
		const headers = new HttpHeaders().set('x-spike-auth', this.spikeAuth);
		const data = { integrations_to_remove: integrationsToRemove };
		return this.http.post(url, data, { headers });
	}
}
