import { Injectable } from '@angular/core';
import { Session } from '@models/session.model';
import { RoleTypes } from '@models/role-types.enum';
import { StorageService } from '@services/storage.service';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	isLoggedIn: boolean = false;
	role: RoleTypes = RoleTypes.NONE;

	// store the URL so we can redirect after logging in
	redirectUrl: string | null = null;

	constructor(private storageService: StorageService) {
		let session = this.storageService.getSession();

		this.isLoggedIn = session != null;
		this.role = session?.user.roleId || RoleTypes.NONE;
	}

	login(session: Session): boolean {
		this.storageService.saveSession(session);
		this.isLoggedIn = true;

		return this.isLoggedIn;
	}

	logout(): void {
		this.storageService.deleteSession();
		this.isLoggedIn = false;
	}
}
